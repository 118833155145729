import { Footer as FooterPrerpType } from "@/services/prepr/queries/getLayoutContent";
import { Path } from "@/utilities/types";
import FooterLink from "./FooterLinks/FooterLink";

interface Props {
  footer: FooterPrerpType;
}

type FooterColumn = Path<FooterPrerpType, ["footer_links_column_1"]>;

const renderColumn = (columnIndex: number) => (col: FooterColumn | undefined) =>
  col?.map((item, i) => {
    const classes =
      i === 3
        ? "text-subline-sm tablet:text-intro"
        : item.bold
        ? "mb-4 text-menu-sm font-bold tracking-wide tablet:mb-8 tablet:text-h4-sm"
        : "mb-4 text-subline-sm tablet:mb-8 tablet:text-intro";
    return (
      item.name && (
        <FooterLink
          key={`c${columnIndex}-${i}`}
          className={classes}
          href={item.link?.[0]?._slug || "/"}
        >
          {item.name}
        </FooterLink>
      )
    );
  });

const FooterLinks: React.FC<Props> = ({ footer }) => (
  <div className="tablet:flex">
    <ul className="tablet:w-1/3 mb-10">{renderColumn(1)(footer?.footer_links_column_1)}</ul>
    <ul className="tablet:w-1/3 mb-10">{renderColumn(2)(footer?.footer_links_column_2)}</ul>
    <ul className="tablet:w-1/3 mb-10">{renderColumn(3)(footer?.footer_links_column_3)}</ul>
  </div>
);

export default FooterLinks;
